import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  OnDestroy,
} from '@angular/core';
import { Router } from '@angular/router';
import { LinkBtnComponent } from '@enginuity/core/molecules/tertiary/link-btn.component';
import { DropdownSinglePieceComponent } from '@enginuity/core/molecules/dropdown-single-piece/dropdown-single-piece.component';
import { Tab } from '@services/core-services/models';
import { ActionBtnComponent } from '@enginuity/core/molecules/action-btn/action-btn.component';
import { MatTabsModule } from '@angular/material/tabs';
import { CdkMenuModule } from '@angular/cdk/menu';

@Component({
  selector: 'app-table-tab',
  templateUrl: './table-tab.component.html',
  styleUrls: ['./table-tab.component.scss'],
  imports: [
    CommonModule,
    LinkBtnComponent,
    DropdownSinglePieceComponent,
    ActionBtnComponent,
    MatTabsModule,
    CdkMenuModule,
  ],
})
export class TableTabComponent implements OnInit, OnChanges, OnDestroy {
  @Input() saveAsView: boolean = false;
  @Input() showMore: boolean = true;
  @Input() showMoreCount: number = 0;
  @Output() selectedTab = new EventEmitter<any>();
  @Output() onRemove = new EventEmitter<any>();
  @Input() withIcon = true;
  @Input() fullWidth = false;
  @Input() state: any;
  @Input() tabContent: Tab[] = [];

  @Input() Type:
    | 'tag'
    | 'completed'
    | 'live'
    | 'scheduled'
    | 'paused'
    | 'ended'
    | 'draft'
    | 'white'
    | 'info'
    | 'cancelled' = 'tag';

  @Input()
  moreTabs() {
    const totalVisibleTabs = this.visibleTabs.length;
    const lastVisibleTab = this.visibleTabs[totalVisibleTabs - 1];
    const lastVisibleTabIndex = this.tabContent.indexOf(lastVisibleTab);

    const nextVisibleTabs = this.tabContent.slice(
      lastVisibleTabIndex + 1,
      lastVisibleTabIndex + 1 + this.showMoreCount
    );

    this.visibleTabs.push(...nextVisibleTabs);
    this.updateShowMore();
  }

  get moreTabsList(): Tab[] {
    return this.tabContent.slice(this.visibleTabs.length);
  }

  public initialVisibleTabs: number = 0;
  public visibleTabs: any[] = [];
  public tabClickedValue: any;

  constructor(private router: Router) {
    this.initialVisibleTabs = this.getVisibleTabsPerRow();
  }

  @Output() onClick = new EventEmitter<any>();

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.updateVisibleTabs(this.initialVisibleTabs);
  }

  tabClicked(tab: any): void {
    this.tabContent.forEach(t => (t.active = false));
    tab.active = true;
    this.selectedTab.emit(tab);
    if (tab.link) {
      this.router.navigate(['/' + tab.link]);
    }
  }

  ngOnInit(): void {
    this.updateVisibleTabs(this.initialVisibleTabs);
    window.addEventListener('resize', this.onResize.bind(this));
  }

  ngOnChanges(changes: SimpleChanges) {
    const tabContent = changes['tabContent']?.currentValue;
    if (tabContent) {
      this.updateVisibleTabs(this.initialVisibleTabs);
      window.addEventListener('resize', this.onResize.bind(this));
    }
  }

  ngOnDestroy(): void {
    window.removeEventListener('resize', this.onResize.bind(this));
  }

  onResize() {
    const tabsPerRow = this.getVisibleTabsPerRow();

    if (tabsPerRow !== this.visibleTabs.length) {
      this.updateVisibleTabs(tabsPerRow);
    }
  }

  updateVisibleTabs(tabsPerRow: number) {
    this.visibleTabs = this.tabContent.slice(0, tabsPerRow);
    this.updateShowMore();
  }

  updateShowMore() {
    const totalVisibleTabs = this.visibleTabs.length;
    this.showMore = this.state === 'more' && this.tabContent.length > totalVisibleTabs;
  }

  getActiveTabIndex(): number {
    const activeTab = this.tabContent.find(tab => tab.active);
    return activeTab ? this.tabContent.indexOf(activeTab) : 0;
  }

  onTabChange(index: number): void {
    this.tabClicked(this.tabContent[index]);
  }

  getVisibleTabsPerRow(): number {
    const windowWidth = window.innerWidth;

    if (windowWidth >= 1920) {
      return 12;
    } else if (windowWidth >= 1600) {
      return 10;
    } else if (windowWidth >= 1366) {
      return 8;
    } else if (windowWidth >= 768) {
      return 3;
    } else {
      return 2;
    }
  }

  selectMoreTab(tab: any) {
    this.tabClicked(tab);
    this.visibleTabs.map((tab: any) => (tab.active = false));
    this.tabClickedValue = tab;
    this.selectedTab.emit(tab);
  }

  handleRemove($event: any, tab: any) {
    $event.stopPropagation();
    this.onRemove.emit(tab);
  }

  trackByTab(index: number, tab: any): string {
    return tab.id;
  }
}
